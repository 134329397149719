
import {
    defineComponent, ref
} from 'vue';
import { customerService, customerServiceType } from '@/data/index';
import HttpRequest from '@/util/axios.config';
import Notice from '@/util/notice.base';

export default defineComponent({

    setup() {
        const phone = ref('');
        const email = ref('');

        const submit = () => {
            const reg = /^[.a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
            if (!reg.test(email.value)) {
                Notice.messageBox('alert',
                    WordList.RuleEmailInvalid,
                    WordList.TabelMessageBoxAlertRequireFailed, 'error')(() => false);
                return;
            }
            if (phone.value === '') {
                Notice.messageBox('alert',
                    WordList.RulePhoneEmpty,
                    WordList.TabelMessageBoxAlertRequireFailed, 'error')(() => false);
                return;
            }

            HttpRequest.post('v3/web/common/customerServer/save', { Phone: phone.value, Email: email.value }, () => true);
        };

        customerService.getCustomerService((res: customerServiceType.GetCustomerServiceType) => {
            phone.value = res.Phone;
            email.value = res.Email;
        });

        return {
            phone,
            email,
            submit
        };
    }
});
